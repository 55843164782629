@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&family=Raleway:wght@400;600&display=swap');

@layer base{
  body{
    @apply font-[Raleway];
  }
  li{
    @apply px-4;
    @apply cursor-pointer;
    
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}
.content-div:hover {
  background-image: 
  linear-gradient(to right, rgba(112, 157, 255, 0.8), 
  hsla(242, 74%, 61%, 0.8))!important;
}